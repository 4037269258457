// React
import React, { useEffect } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import AnimateHeight from 'react-animate-height';

// Utilities
import { localize } from 'utilities';
import { useTheme, useCart } from 'hooks';

// Components
import SEO from 'components/seo';
import { PageNavigation } from 'components/pageNavigation';
import CartSection from 'components/_cart/cartSection';
import Basket from 'components/_cart/basket';
import BasketOverview from 'components/_cart/basketOverview';
import Login from 'components/_cart/login';
import Shipping from 'components/_cart/shipping';
import PersonalInformation from 'components/_cart/personalInformation';
import PaymentInformation from 'components/_cart/paymentInformation';
import Summary from 'components/_cart/summary';
import Checkout from 'components/_cart/checkout';

const CartPageComponent = ({ pageContext, content, location }) => {
    // Hook: Cart
    const { cart, order, checkout, loading, cartUtils } = useCart();

    const { locale } = pageContext;
    const { seoMetaTags } = content;

    // Labels
    const labels = {
        CART: JSON.parse(content.labels),
        ACCOUNT: JSON.parse(content.account.labels),
    };

    // Set theme
    const theme = useTheme(s.color('white'));

    // Cart state
    const [cartState, setCartState] = useGlobal('cartState');

    useEffect(() => {
        if (cartState > 3) {
            setCartState(3);
        }
    }, []);

    // Sections and content
    const sections = [
        {
            validation() {
                return true;
            },
            title: labels.CART.SECTIONS.PRODUCTS.TITLE,
            content(index) {
                return (
                    <>
                        <Basket
                            {...{
                                labels,
                                theme,
                                completeSection() {
                                    setCartState(index + 1);
                                },
                            }}
                        />
                    </>
                );
            },
        },
        {
            validation() {
                return true;
            },
            title: labels.CART.SECTIONS.USER.TITLE,
            content(index) {
                return (
                    <Login
                        {...{
                            locale,
                            labels,
                            theme,
                            location,
                            completeSection() {
                                setCartState(index + 1);
                            },
                        }}
                    />
                );
            },
        },
        {
            validation() {
                return true;
            },
            title: labels.CART.SECTIONS.PERSONAL_INFORMATION.TITLE,
            content(index) {
                return (
                    <PersonalInformation
                        {...{
                            labels,
                            theme,
                            completeSection() {
                                setCartState(index + 1);
                            },
                        }}
                    />
                );
            },
        },
        {
            validation() {
                return (
                    cartUtils.hasRegularShipments() ||
                    cartUtils.hasSubscriptions() ||
                    cartUtils.hasPreorderProducts()
                );
            },
            title: labels.CART.SECTIONS.SHIPPING.TITLE,
            content(index) {
                return (
                    <Shipping
                        {...{
                            labels,
                            theme,
                            completeSection() {
                                setCartState(index + 1);
                            },
                        }}
                    />
                );
            },
        },
        {
            validation() {
                return true;
            },
            title: labels.CART.SECTIONS.PAYMENT.TITLE,
            content(index) {
                return (
                    <PaymentInformation
                        {...{
                            labels,
                            theme,
                            completeSection() {
                                setCartState(index + 1);
                            },
                        }}
                    />
                );
            },
        },
        {
            validation() {
                return true;
            },
            title: labels.CART.SECTIONS.CONFIRM.TITLE,
            content() {
                return (
                    <>
                        <p className="p-12 mb-16 text-center t-h7 bg-pink">
                            {labels.CART.SECTIONS.CONFIRM.NOTICE}
                        </p>
                        <BasketOverview {...{ cart, labels }} />
                        <div className="lg:hidden">
                            <Summary {...{ labels, theme, cartState, cart }} />
                        </div>
                        <Checkout
                            {...{
                                labels,
                                theme,
                                async checkoutMethod() {
                                    try {
                                        await checkout();
                                        setCartState(0);
                                    } catch (error) {
                                        console.warn(error);
                                    }
                                },
                                loadingCheckout: loading,
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <div className="pt-100 lg:pt-140">
                    <PageNavigation {...{ theme }}>
                        {cart?.items?.length > 0 || order?.items?.length > 0 ? (
                            <span>
                                {order
                                    ? labels.CART.COMPLETE
                                    : labels.CART.TITLE}
                            </span>
                        ) : (
                            <span>{labels.CART.EMPTY}</span>
                        )}
                    </PageNavigation>
                </div>

                {/* Cart exists with content in it */}
                <AnimateHeight
                    duration={300}
                    height={
                        cart?.items?.length > 0 && order === null ? 'auto' : 0
                    }>
                    <div className="relative flex flex-col pt-48 lg:flex-row lg:justify-between">
                        <div
                            className="mb-[500px]"
                            css={css`
                                ${s.grid.columns('width', {
                                    0: 4,
                                    20: 6,
                                    30: 8,
                                })};
                            `}>
                            {sections
                                .filter(section => section.validation())
                                .map((section, index) => (
                                    <CartSection
                                        key={index}
                                        {...{
                                            title: `${index + 1}. ${
                                                section.title
                                            }`,
                                            theme,
                                            sectionIndex: index,
                                            sectionIndexState: cartState,
                                            setSectionIndexState: setCartState,
                                            labels,
                                        }}>
                                        {section.content(index)}
                                    </CartSection>
                                ))}
                        </div>
                        <div
                            className="sticky top-0 self-start hidden pt-48 -mt-48 lg:block"
                            css={css`
                                ${s.grid.columns('width', {
                                    0: 4,
                                    20: 6,
                                    30: 3,
                                })};
                            `}>
                            <Summary {...{ labels, theme, cartState, cart }} />
                        </div>
                    </div>
                </AnimateHeight>

                {/* Order complete */}
                <AnimateHeight duration={300} height={order ? 'auto' : 0}>
                    <div
                        className="mt-48"
                        css={css`
                            ${s.grid.columns('width', {
                                0: 4,
                                20: 3,
                                30: 6,
                            })};
                        `}>
                        <Summary
                            {...{
                                labels,
                                theme,
                                cartState: 5,
                                cart: order,
                                order: true,
                            }}
                        />
                    </div>
                </AnimateHeight>
            </s.layout.PageContainer>
        </>
    );
};

const NamedCartPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageCart, allDatoCmsPageAccount } =
        useStaticQuery(graphql`
            query {
                allDatoCmsPageCart {
                    edges {
                        node {
                            locale
                            labels
                            seoMetaTags {
                                ...SEO
                            }
                        }
                    }
                }
                allDatoCmsPageAccount {
                    edges {
                        node {
                            locale
                            labels
                        }
                    }
                }
            }
        `);

    return (
        <CartPageComponent
            {...{
                content: {
                    ...localize(allDatoCmsPageCart, locale),
                    account: localize(allDatoCmsPageAccount, locale),
                },
                ...props,
            }}
        />
    );
};

export default NamedCartPageComponent;
