// React
import React from 'react';

// Packages

// Utilities
import { useCart } from 'hooks';

// Components
import CardInformation from './cardInformation';
import CouponCode from 'components/_cart/couponCode';

const PaymentInformationComponent = ({ labels, theme, completeSection }) => {
    // Hook: Cart
    const { cart, cartUtils } = useCart();

    return (
        <>
            <div className="flex flex-col">
                {!cartUtils.hasSubscriptions() && (
                    <CouponCode
                        {...{
                            labels,
                        }}
                    />
                )}

                <CardInformation
                    {...{
                        theme,
                        labels,
                        completeSection,
                    }}
                />
            </div>
        </>
    );
};

export default PaymentInformationComponent;
