// React
import React from 'react';

// Packages

// Utilities

// Components
import Button from 'components/button';

const CheckoutComponent = ({
    labels,
    theme,
    checkoutMethod,
    loadingCheckout,
}) => {
    return (
        <>
            <div className="flex justify-end mt-12">
                <div className="flex pb-12 border-b-[12px] border-pink">
                    <Button
                        {...{
                            async onClick() {
                                await checkoutMethod();
                            },
                            loading: loadingCheckout,
                            theme,
                            arrow: true,
                            label: labels.CART.SECTIONS.CONFIRM.CONTINUE,
                            feedback: labels.CART.SECTIONS.CONFIRM.COMPLETE,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default CheckoutComponent;
