// React
import React from 'react';

// Packages
import dayjs from 'dayjs';
import { Transition } from '@headlessui/react';
import cc from 'classcat';

// Utilities
import { useCart } from 'hooks';
import { unit } from 'utilities';

// Components
import Image from 'components/image';
import Button from 'components/button';

const BasketComponent = ({ labels, theme, completeSection }) => {
    // Hook: Cart
    const { cartUtils, deleteItem, loading: disabled, updateItem } = useCart();

    return (
        <>
            <div className="flex flex-col">
                <div className="relative flex flex-col">
                    {cartUtils.items().map(item => {
                        // Get product data from metadata (datocms)
                        const product = item?.metadata?.product ?? {};

                        // Model api key
                        const model =
                            product.model?.apiKey?.toUpperCase() ?? null;

                        // Get item data
                        let weight = null;
                        let option = null;
                        let image = null;
                        switch (model) {
                            case 'SUBSCRIPTION':
                                break;
                            case 'FILTER_COFFEE':
                            case 'ESPRESSO':
                                weight =
                                    product.variations.filter(
                                        variant =>
                                            variant.sku === item.product.sku
                                    )[0]?.weight ?? null;
                                image = product.thumbnail;

                                break;
                            case 'EQUIPMENT':
                            case 'OTHER_PRODUCT':
                                option = item?.variant?.name ?? null;
                                image = product.thumbnail;
                                break;
                            case 'BUNDLE':
                                image = product.thumbnail;

                                // Handle combination products
                                if (product.combinationProducts?.length > 0) {
                                    const combinationProductSku =
                                        item?.variant?.name;

                                    option =
                                        product.combinationProducts.filter(
                                            p => {
                                                if (p.variations?.length > 0) {
                                                    return p.variations.some(
                                                        v =>
                                                            v.sku ===
                                                            combinationProductSku
                                                    );
                                                } else {
                                                    return (
                                                        p.sku ===
                                                        combinationProductSku
                                                    );
                                                }
                                            }
                                        )[0]?.name ?? 'N/A';
                                }

                                break;
                            case 'COURSE':
                                image = product.image;

                                // Get date
                                const selectedValue = item.variant.name;

                                const selectedDateTime = selectedValue.substr(
                                    3,
                                    selectedValue.length
                                );

                                // 27/9-1730
                                option = dayjs(selectedDateTime, [
                                    'D/M-HHmm',
                                    'D/MM-HHmm',
                                    'DD/M-HHmm',
                                    'DD/MM-HHmm',
                                ]).format('MMMM. D, HH.mm');

                                break;
                            case 'SUBSCRIPTION_GIFT_CARD':
                                image = product.thumbnail;
                                break;
                            case 'GIFT_CARD':
                            case 'BAR_GIFT_CARD':
                                image = product.thumbnail;

                                break;
                            case 'PRESALE_PRODUCT':
                                image = product.thumbnail;
                                option = item?.variant?.name;
                                break;
                        }

                        return (
                            <div key={item.id} className="flex mb-12 bg-gray">
                                {item.metadata && (
                                    <div className="flex items-center flex-grow">
                                        {image && (
                                            <div className="hidden m-24 sm:block w-128 h-128">
                                                <Image
                                                    {...{
                                                        source: image.fluid,
                                                        alt: product.name,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className="flex flex-col flex-grow">
                                            {/* Information */}
                                            <div
                                                className={cc([
                                                    'flex items-baseline p-24 sm:pr-32 sm:pl-0',
                                                    {
                                                        'ml-24':
                                                            model ===
                                                            'SUBSCRIPTION',
                                                    },
                                                ])}>
                                                <div className="relative t-product">
                                                    {product.name}
                                                    <div className="left-0 flex flex-col items-baseline mt-4 sm:space-x-20 sm:absolute sm:flex-row top-full">
                                                        {option && (
                                                            <span className="t-h17 opacity-60 whitespace-nowrap">
                                                                {option}
                                                            </span>
                                                        )}
                                                        {weight && (
                                                            <span className="t-h17 opacity-60 whitespace-nowrap">
                                                                {`${unit.format(
                                                                    weight
                                                                )} ${unit.unit(
                                                                    weight
                                                                )}`}
                                                            </span>
                                                        )}
                                                        <span className="t-h17 whitespace-nowrap">
                                                            {model !==
                                                                'SUBSCRIPTION' && (
                                                                <>
                                                                    {unit.currency(
                                                                        item.price +
                                                                            item.tax_each
                                                                    )}{' '}
                                                                    DKK
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>

                                                <span className="flex justify-end ml-auto t-h13 whitespace-nowrap">
                                                    {unit.currency(
                                                        item.price_total +
                                                            item.tax_total
                                                    )}{' '}
                                                    DKK
                                                </span>
                                            </div>

                                            {/* Add/remove */}
                                            <div className="flex justify-end p-16 space-x-20 sm:pr-32">
                                                <Button
                                                    {...{
                                                        onClick() {
                                                            deleteItem({
                                                                id: item.id,
                                                            });
                                                        },
                                                        theme,
                                                        type: 'secondary',
                                                        label: labels.CART
                                                            .SECTIONS.PRODUCTS
                                                            .REMOVE,
                                                    }}
                                                />
                                                {model !== 'SUBSCRIPTION' && (
                                                    <div className="col-2">
                                                        <div className="flex border">
                                                            <Button
                                                                {...{
                                                                    onClick() {
                                                                        updateItem(
                                                                            {
                                                                                id: item.id,
                                                                                quantity:
                                                                                    Math.max(
                                                                                        item.quantity -
                                                                                            1,
                                                                                        1
                                                                                    ),
                                                                            }
                                                                        );
                                                                    },
                                                                    theme,
                                                                    type: 'secondary',
                                                                    label: '−',
                                                                    disabled,
                                                                    className:
                                                                        '!border-none',
                                                                }}
                                                            />
                                                            <div className="flex items-center justify-center w-1/3 t-h16">
                                                                {item.quantity}
                                                            </div>
                                                            <Button
                                                                {...{
                                                                    onClick() {
                                                                        updateItem(
                                                                            {
                                                                                id: item.id,
                                                                                quantity:
                                                                                    item.quantity +
                                                                                    1,
                                                                            }
                                                                        );
                                                                    },
                                                                    theme,
                                                                    type: 'secondary',
                                                                    label: '+',
                                                                    disabled,
                                                                    className:
                                                                        '!border-none',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <Transition
                        show={disabled}
                        enter="transition-medium"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-medium"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        className="absolute inset-0 mb-12 bg-green z-above bg-opacity-40"
                    />
                </div>
                <div className="flex justify-end mt-24">
                    <Button
                        {...{
                            onClick() {
                                completeSection();
                            },
                            theme,
                            type: 'primary',
                            label: labels.CART.SECTIONS.PRODUCTS.CONTINUE,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default BasketComponent;
