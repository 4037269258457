// React
import React, { useState } from 'react';

// Packages
import cc from 'classcat';
import { useForm } from 'react-hook-form';

// Utilities
import { useCart } from 'hooks';

// Components

const CouponComponent = ({ labels }) => {
    // Hook: useCart
    const { applyCoupon, cart, loading } = useCart();

    // Hook: useForm setup
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    // State: Invalid coupon code
    const [invalid, setInvalid] = useState(false);

    function onSubmit(data) {
        const { coupon } = data;
        setInvalid(false);
        applyCoupon({ coupon: coupon.replace(/-/g, '') }).then(response => {
            if (response === 'invalid_code') {
                setInvalid(true);
            }
        });
    }

    // Inputs
    const input = {
        namespace: labels.CART.SECTIONS.PAYMENT.INPUTS.COUPON,
        name: 'coupon',
        defaultValue: '',
        type: 'text',
    };

    // Errors
    const error = errors[input.name];

    return (
        <div className="flex items-start mb-32 space-x-32">
            <label className="flex flex-col">
                <span className="mb-8 opacity-50 t-h17">
                    {input.namespace.LABEL}
                </span>
                <input
                    disabled={cart?.giftcard_total}
                    {...register(input.name, { required: true })}
                    type="text"
                    placeholder={input.namespace.LABEL}
                    defaultValue={input.defaultValue}
                    className={cc([
                        'flex-grow py-10 outline-none bg-opacity-40 bg-gray px-14 t-h15 focus:ring-2 ring-pink sm:w-[230px] md:w-[260px]',
                        {
                            'ring-2 ring-error focus:ring-2': error || invalid,
                        },
                    ])}
                />
                {invalid && (
                    <span className="mt-8 t-h18">
                        {labels.CART.SECTIONS.PAYMENT.COUPON_INVALID}
                    </span>
                )}
            </label>
            {cart?.giftcard_total ? (
                <span className="pt-36 t-h17">
                    {labels.CART.SECTIONS.PAYMENT.COUPON_APPLIED}
                </span>
            ) : (
                <button
                    onClick={() => {
                        handleSubmit(onSubmit)();
                    }}
                    className={cc([
                        'pt-36 t-h17',
                        {
                            underline: !loading,
                        },
                    ])}>
                    {loading
                        ? '...'
                        : labels.CART.SECTIONS.PAYMENT.COUPON_APPLY}
                </button>
            )}
        </div>
    );
};

CouponComponent.propTypes = {};

CouponComponent.defaultProps = {};

export default CouponComponent;
