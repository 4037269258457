// React
import React, { useState, useEffect } from 'react';

// Packages
import cc from 'classcat';

// Utilities
import { useCart, useRollbar } from 'hooks';

// Components
import Button from 'components/button';

const CardInformationComponent = ({ labels, theme, completeSection }) => {
    // Hook: Cart
    const { addPaymentElements, tokenize, cart, cartUtils } = useCart();

    // Rollbar
    const rollbarError = useRollbar('error');

    // State
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    async function submit(event) {
        event.preventDefault();
        setError(false);
        setLoading(true);
        if (cartUtils.amountLeftToPay() || cartUtils.hasSubscriptions()) {
            await tokenize({
                card: {
                    onError: err => {
                        rollbarError(
                            'onError in payment elements tokenize',
                            err
                        );
                        setError(true);
                        setLoading(false);
                        return false;
                    },
                    onSuccess: response => {
                        setLoading(false);
                        completeSection();
                        return true;
                    },
                },
            });
        } else {
            setLoading(false);
            completeSection();
            return true;
        }
    }

    useEffect(() => {
        if (
            cart &&
            (cartUtils.amountLeftToPay() || cartUtils.hasSubscriptions())
        ) {
            addPaymentElements('stripe-payment-element');
        }
    }, []);

    return (
        <form onSubmit={submit}>
            {/* Only show card information if there is something left to pay */}
            {(cartUtils.amountLeftToPay() || cartUtils.hasSubscriptions()) && (
                <div className="flex flex-col mb-32">
                    <div className="flex flex-col">
                        <span className="mb-8 opacity-50 t-h17">
                            {labels.CART.SECTIONS.PAYMENT.CREDIT_CARD}
                        </span>
                        <div
                            id="stripe-payment-element"
                            className={cc([
                                'flex-grow py-[13px] outline-none bg-opacity-40 bg-gray px-14 t-h15',
                                {
                                    'ring-2 ring-error': error,
                                },
                            ])}
                        />
                    </div>
                    {error && (
                        <span className="mt-8 t-h18">
                            {labels.CART.SECTIONS.PAYMENT.CREDIT_CARD_ERROR}
                        </span>
                    )}
                </div>
            )}
            <div className="flex justify-end mt-12 space-x-12">
                <Button
                    {...{
                        submit: true,
                        theme,
                        loading,
                        label: labels.CART.SECTIONS.PAYMENT.CONTINUE,
                        feedback: labels.CART.SECTIONS.PAYMENT.COMPLETE,
                    }}
                />
            </div>
        </form>
    );
};

CardInformationComponent.propTypes = {};

CardInformationComponent.defaultProps = {};

export default CardInformationComponent;
