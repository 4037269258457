// React
import React, { useRef } from 'react';

// Packages
import AnimateHeight from 'react-animate-height';
import cc from 'classcat';

// Utilities
import { scrollTo, scrollTop } from 'utilities';

// Components
import Button from 'components/button';

const CartSectionComponent = ({
    children,
    title,
    sectionIndex,
    sectionIndexState,
    setSectionIndexState,
    theme,
    labels,
}) => {
    // Top
    const top = useRef();
    return (
        <>
            <div className="flex flex-col mb-48">
                {title && (
                    <h3
                        className="flex items-center mb-24 space-x-24 t-h8"
                        ref={top}>
                        <span>{title}</span>
                        <span
                            className={cc([
                                'transition-default',
                                {
                                    'opacity-100':
                                        sectionIndex < sectionIndexState,
                                    'opacity-0 pointer-events-none':
                                        sectionIndex >= sectionIndexState,
                                },
                            ])}>
                            <Button
                                {...{
                                    onClick() {
                                        setSectionIndexState(sectionIndex);
                                    },
                                    theme,
                                    type: 'secondary',
                                    label: labels.CART.SECTIONS.SHOW,
                                }}
                            />
                        </span>
                    </h3>
                )}
                <AnimateHeight
                    duration={300}
                    onAnimationEnd={() => {
                        if (sectionIndex === sectionIndexState) {
                            scrollTo(scrollTop(top.current) - 20);
                        }
                    }}
                    height={sectionIndex === sectionIndexState ? 'auto' : 0}>
                    {children}
                </AnimateHeight>
            </div>
        </>
    );
};

export default CartSectionComponent;
