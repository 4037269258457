// React
import React, { useEffect, useState } from 'react';

// Packages
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useCart, useShipping } from 'hooks';

// Components
import Select from 'components/_input/select';
import Button from 'components/button';

const ShippingComponent = ({ labels, theme, completeSection }) => {
    // Hook: useCart
    const {
        loading: loadingCart,
        cartUtils,
        updateShippingInformation,
    } = useCart();

    // Hook: useShipping
    const { quotes, subscriptionQuotes, preorderQuotes, getDropPoints } =
        useShipping();

    // Hook: useForm setup with provider
    const methods = useForm();
    const { handleSubmit, control } = methods;

    const [error, setError] = useState(false);

    function onSubmit(data) {
        setError(false);

        const {
            shipping_select,
            shipping_select_droppoint,
            shipping_select_preorder,
            shipping_select_preorder_droppoint,
        } = data;

        // Handle normal shipments
        let defaultShippingData = {};
        if (cartUtils.hasRegularShipments()) {
            // Get shipping info from quotes
            const defaultShipping = quotes[shipping_select];

            // Check for drop point
            const defaultShippingDropPoint = defaultShipping?.shipping_rate
                ?.require_drop_point
                ? JSON.parse(shipping_select_droppoint)
                : null;

            const {
                carrier_alias,
                carrier_code,
                carrier_name,
                currency,
                price,
                shipping_rate,
                tax_percent,
            } = defaultShipping;

            defaultShippingData = {
                price: defaultShipping.price,
                data: {
                    carrier_alias,
                    carrier_code,
                    carrier_name,
                    currency,
                    price,
                    shipping_rate,
                    tax_percent,
                    drop_point: defaultShippingDropPoint,
                },
            };
        }

        // Handle preorder shipments
        let preorderShippingData = {};
        if (cartUtils.hasPreorderProducts()) {
            // Get shipping info from quotes
            const preorderShipping = preorderQuotes[shipping_select_preorder];

            // Check for drop point
            const preorderShippingDropPoint = preorderShipping?.shipping_rate
                ?.require_drop_point
                ? JSON.parse(shipping_select_preorder_droppoint)
                : null;

            const {
                carrier_alias,
                carrier_code,
                carrier_name,
                currency,
                price,
                shipping_rate,
                tax_percent,
            } = preorderShipping;

            preorderShippingData = {
                price: preorderShipping.price,
                data: {
                    carrier_alias,
                    carrier_code,
                    carrier_name,
                    currency,
                    price,
                    shipping_rate,
                    tax_percent,
                    drop_point: preorderShippingDropPoint,
                },
            };
        }

        // Handle subscriptions
        let subscriptionShippingData = [];
        if (cartUtils.hasSubscriptions()) {
            subscriptionShippingData = cartUtils
                .subscriptions()
                .reduce((acc, subscription, index) => {
                    // Get dynamic selected value
                    const selectedValue =
                        data[`shipping_select_subscription_${index}`];

                    // Get shipping info from quotes
                    const shipping = subscriptionQuotes[index][selectedValue];

                    // Check for drop point
                    const dropPoint = shipping?.shipping_rate
                        ?.require_drop_point
                        ? JSON.parse(
                              data[
                                  `shipping_select_droppoint_subscription_${index}`
                              ]
                          )
                        : null;

                    const {
                        carrier_alias,
                        carrier_code,
                        carrier_name,
                        currency,
                        price,
                        shipping_rate,
                        tax_percent,
                    } = shipping;

                    return [
                        ...acc,
                        {
                            id: subscription.id,
                            price: shipping.price,
                            data: {
                                carrier_alias,
                                carrier_code,
                                carrier_name,
                                currency,
                                price,
                                shipping_rate,
                                tax_percent,
                                drop_point: dropPoint,
                            },
                        },
                    ];
                }, []);
        }

        updateShippingInformation({
            ...defaultShippingData,
            preorderData: preorderShippingData,
            subscriptionData: subscriptionShippingData,
        }).then(cart => {
            if (
                (cartUtils.hasRegularShipments() &&
                    (cart.metadata?.shipping ?? false)) ||
                cartUtils.hasSubscriptions() ||
                cartUtils.hasPreorderProducts()
            ) {
                setError(false);
                completeSection();
            } else {
                setError(true);
            }
        });
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col space-y-64">
                        {cartUtils.hasSubscriptions() && (
                            <div className="flex flex-col items-start space-y-32">
                                {cartUtils
                                    .subscriptions()
                                    .map((item, index) => (
                                        <ShippingSelectComponent
                                            key={item.id}
                                            {...{
                                                // Shared
                                                title: item.metadata.product
                                                    .name,
                                                control,
                                                // Shipping method
                                                shippingMethodNamespace:
                                                    labels.CART.SECTIONS
                                                        .SHIPPING.INPUTS
                                                        .SHIPPING_SELECT_SUBSCRIPTION,
                                                shippingMethodInputName: `shipping_select_subscription_${index}`,
                                                required:
                                                    cartUtils.hasSubscriptions(),
                                                quoteOptions:
                                                    subscriptionQuotes[index],
                                                // Drop point
                                                dropPointMethodNamespace:
                                                    labels.CART.SECTIONS
                                                        .SHIPPING.INPUTS
                                                        .DROPPOINT_SELECT,
                                                dropPointMethodInputName: `shipping_select_droppoint_subscription_${index}`,
                                                getDropPoints,
                                            }}
                                        />
                                    ))}
                            </div>
                        )}

                        {cartUtils.hasRegularShipments() && (
                            <ShippingSelectComponent
                                {...{
                                    // Shared
                                    title: cartUtils.hasSubscriptions()
                                        ? labels.CART.SECTIONS.SHIPPING
                                              .NON_SUBSCRIPTION_PRODUCTS
                                        : null,
                                    control,
                                    // Shipping method
                                    shippingMethodNamespace:
                                        labels.CART.SECTIONS.SHIPPING.INPUTS
                                            .SHIPPING_SELECT,
                                    shippingMethodInputName: 'shipping_select',
                                    required: cartUtils.hasRegularShipments(),
                                    quoteOptions: quotes,
                                    // Drop point
                                    dropPointMethodNamespace:
                                        labels.CART.SECTIONS.SHIPPING.INPUTS
                                            .DROPPOINT_SELECT,
                                    dropPointMethodInputName:
                                        'shipping_select_droppoint',
                                    getDropPoints,
                                }}
                            />
                        )}

                        {cartUtils.hasPreorderProducts() && (
                            <ShippingSelectComponent
                                {...{
                                    // Shared
                                    title: cartUtils.hasSubscriptions()
                                        ? labels.CART.SECTIONS.SHIPPING
                                              .NON_SUBSCRIPTION_PRODUCTS
                                        : null,
                                    control,
                                    // Shipping method
                                    shippingMethodNamespace:
                                        labels.CART.SECTIONS.SHIPPING.INPUTS
                                            .SHIPPING_SELECT_PREORDER,
                                    shippingMethodInputName:
                                        'shipping_select_preorder',
                                    required: cartUtils.hasPreorderProducts(),
                                    quoteOptions: preorderQuotes,
                                    // Drop point
                                    dropPointMethodNamespace:
                                        labels.CART.SECTIONS.SHIPPING.INPUTS
                                            .DROPPOINT_SELECT,
                                    dropPointMethodInputName:
                                        'shipping_select_preorder_droppoint',
                                    getDropPoints,
                                }}
                            />
                        )}

                        {error && (
                            <div className="flex flex-col">
                                <p className="mb-24 text-error t-h17">
                                    {labels.CART.SECTIONS.SHIPPING.ERROR}
                                </p>
                            </div>
                        )}

                        <div className="flex justify-end mt-12 space-x-12">
                            <Button
                                {...{
                                    submit: true,
                                    loading: loadingCart,
                                    theme,
                                    label: labels.CART.SECTIONS.SHIPPING
                                        .CONTINUE,
                                    feedback:
                                        labels.CART.SECTIONS.SHIPPING.COMPLETE,
                                }}
                            />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

const ShippingSelectComponent = ({
    // Shared
    title,
    control,
    // Shipping method
    shippingMethodNamespace,
    shippingMethodInputName,
    required,
    quoteOptions,
    // Drop point
    dropPointMethodNamespace,
    dropPointMethodInputName,
    getDropPoints,
}) => {
    // State: Drop points
    const [showDropPoints, setShowDropPoints] = useState(false);
    const [dropPointOptions, setDropPointOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    // Watch the select for shipping drop points to make drop points required
    const watchShippingSelect = useWatch({
        control,
        name: shippingMethodInputName,
        defaultValue: '',
    });

    // Effect: React to watchShippingSelect to check for drop points
    useEffect(async () => {
        if (quoteOptions && watchShippingSelect) {
            // Loading
            setLoading(true);
            // Reset drop points
            setShowDropPoints(false);
            const shipping = quoteOptions[watchShippingSelect];
            if (shipping.shipping_rate.require_drop_point) {
                // Set immediate required
                setShowDropPoints(true);

                // Fetch drop points
                const data = await getDropPoints({
                    carrier: shipping.shipping_rate.carrier_id,
                    service: shipping.shipping_rate.carrier_service_code,
                });
                setDropPointOptions(data);
            }
            // Loading
            setLoading(false);
        }
    }, [watchShippingSelect]);

    return (
        <div className="flex flex-col items-start">
            {title && <h4 className="mb-8 t-h14">{title}</h4>}
            <Select
                {...{
                    loading,
                    input: {
                        namespace: shippingMethodNamespace,
                        name: shippingMethodInputName,
                        type: 'select',
                        required,
                        options:
                            quoteOptions?.length > 0
                                ? quoteOptions?.map((item, index) => ({
                                      value: index,
                                      label: `${item.shipping_rate.name} (${item.price} ${item.currency})`,
                                  }))
                                : [],
                    },
                }}
            />

            {/* Show if there are drop points related to the shipping option */}
            <AnimateHeight duration={300} height={showDropPoints ? 'auto' : 0}>
                <div className="flex flex-col items-start mt-16">
                    <Select
                        {...{
                            loading,
                            input: {
                                namespace: dropPointMethodNamespace,
                                name: dropPointMethodInputName,
                                type: 'select',
                                disabled:
                                    dropPointOptions.length === 0 || loading,
                                required: showDropPoints,
                                options: dropPointOptions.map(item => ({
                                    value: JSON.stringify(item),
                                    label: `${item.name} (${item.address_1}, ${
                                        item.zip
                                    } ${item.city}) - ${item.distance.toFixed(
                                        1
                                    )} km`,
                                })),
                            },
                        }}
                    />
                </div>
            </AnimateHeight>
        </div>
    );
};

export default ShippingComponent;
