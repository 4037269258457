// React
import React from 'react';

// Packages
import t from 'prop-types';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useAccount, useCart } from 'hooks';
import { localizeLink } from 'utilities';
import { transitionNavigate } from 'transition';

// Components
import InputElement from 'components/_input/inputElement';
import Button from 'components/button';
import Toggle from 'components/toggle';

const LoginComponent = ({ labels, theme, completeSection, locale }) => {
    // Hook: useForm setup with provider
    const methods = useForm();
    const { handleSubmit, control } = methods;

    const watchCreateUserToggle = useWatch({
        control,
        name: 'createUserToggle',
    });

    const watchCreateUserPassword = useWatch({
        control,
        name: 'password',
    });

    // Hook: useCart
    const { cartUtils } = useCart();

    // Hook: useAccount
    const {
        account,
        initAccount,
        loading: loadingAccount,
        login,
        logout,
        error,
    } = useAccount();

    function onSubmit(data) {
        const { email, password } = data;

        if (watchCreateUserToggle) {
            initAccount({ email, password }).then(() => {
                completeSection();
            });
        } else {
            login({ email, password }).then(account => {
                if (account) {
                    completeSection();
                }
            });
        }
    }

    // All inputs
    const inputs = {
        login: [
            {
                namespace: labels.ACCOUNT.LOGIN.INPUTS.USER,
                name: 'email',
                defaultValue: '',
                pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                type: 'text',
                required: !watchCreateUserToggle,
            },
            {
                namespace: labels.ACCOUNT.LOGIN.INPUTS.PASSWORD,
                name: 'password',
                defaultValue: '',
                type: 'password',
                showForgot: true,
                required: !watchCreateUserToggle,
            },
        ],
        create: [
            {
                namespace: labels.ACCOUNT.LOGIN.INPUTS.USER,
                name: 'email',
                defaultValue: '',
                pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                type: 'text',
                required: watchCreateUserToggle,
            },
            {
                namespace: labels.ACCOUNT.LOGIN.INPUTS.PASSWORD,
                name: 'password',
                defaultValue: '',
                type: 'password',
                minLength: 8,
                required: watchCreateUserToggle,
            },
            {
                namespace: labels.ACCOUNT.LOGIN.INPUTS.PASSWORD_CONFIRM,
                name: 'password_confirm',
                defaultValue: '',
                type: 'password',
                minLength: 8,
                validate: value =>
                    value === watchCreateUserPassword ||
                    labels.ACCOUNT.LOGIN.INPUTS.PASSWORD_CONFIRM.ERRORS
                        .VALIDATE,
                required: watchCreateUserToggle,
            },
        ],
    };

    return (
        <>
            <AnimateHeight duration={300} height={account ? 'auto' : 0}>
                <span className="t-h10">
                    {account?.name
                        ? `${labels.ACCOUNT.LOGIN.LOGGED_IN_AS} ${account?.name}`
                        : labels.ACCOUNT.LOGIN.LOGGED_IN}
                </span>

                <div className="flex items-end justify-end mt-12 space-x-12">
                    <Button
                        {...{
                            async onClick() {
                                await logout();
                            },
                            type: 'secondary',
                            loading: loadingAccount,
                            theme,
                            label: labels.ACCOUNT.LOGIN.LOGOUT_LABEL,
                        }}
                    />
                    <Button
                        {...{
                            onClick() {
                                completeSection();
                            },
                            theme,
                            label: labels.CART.SECTIONS.USER.CONTINUE,
                        }}
                    />
                </div>
            </AnimateHeight>
            <AnimateHeight duration={300} height={!account ? 'auto' : 0}>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {error && !watchCreateUserToggle && (
                            <div className="flex flex-col">
                                <p className="mb-24 text-error t-h17">
                                    {labels.ACCOUNT.LOGIN.LOGIN_ERROR}
                                </p>
                            </div>
                        )}
                        <div className="flex flex-col p-24 mb-48 space-y-12 bg-gray bg-opacity-70 md:flex-row md:space-y-0 md:space-x-48 md:justify-between">
                            <div className="flex flex-col space-y-12">
                                <p className="t-h14">
                                    {labels.ACCOUNT.LOGIN.PLATFORM_CHANGE}
                                </p>
                            </div>
                            <Button
                                className="self-start flex-shrink-0"
                                type="secondary"
                                label={labels.ACCOUNT.LOGIN.NEW_PASSWORD}
                                onClick={() =>
                                    transitionNavigate({
                                        from: location,
                                        to: localizeLink(
                                            `/reset-password`,
                                            locale
                                        ),
                                    })
                                }
                            />
                        </div>
                        <div className="flex flex-wrap justify-between">
                            {watchCreateUserToggle &&
                                inputs['create']
                                    .slice(0, 2)
                                    .map((input, index) => (
                                        <InputElement
                                            key={index}
                                            {...{ input }}
                                        />
                                    ))}
                            {!watchCreateUserToggle &&
                                inputs['login'].map((input, index) => (
                                    <InputElement
                                        key={index}
                                        {...{ input, locale }}
                                    />
                                ))}
                        </div>

                        <div className="flex flex-wrap justify-between">
                            <div className="order-2 md:order-1">
                                <Toggle
                                    {...{
                                        control,
                                        label: labels.ACCOUNT.LOGIN.INPUTS
                                            .TOGGLE.LABEL,
                                        name: 'createUserToggle',
                                    }}
                                />
                            </div>
                            <AnimateHeight
                                className="order-1 md:order-2"
                                duration={300}
                                height={watchCreateUserToggle ? 'auto' : 0}>
                                {inputs['create']
                                    .slice(2, 3)
                                    .map((input, index) => (
                                        <InputElement
                                            key={index}
                                            {...{ input }}
                                        />
                                    ))}
                            </AnimateHeight>
                        </div>

                        <div className="flex items-end justify-end mt-12 space-x-12">
                            {!cartUtils.hasSubscriptions() && (
                                <Button
                                    {...{
                                        onClick() {
                                            completeSection();
                                        },
                                        type: 'secondary',
                                        theme,
                                        label: labels.CART.SECTIONS.USER
                                            .CONTINUE_AS_GUEST,
                                    }}
                                />
                            )}
                            <Button
                                {...{
                                    submit: true,
                                    loading: loadingAccount,
                                    theme,
                                    label: watchCreateUserToggle
                                        ? labels.ACCOUNT.LOGIN.CREATE_USER
                                        : labels.ACCOUNT.LOGIN.LOGIN_LABEL,
                                }}
                            />
                        </div>
                    </form>
                </FormProvider>
            </AnimateHeight>
        </>
    );
};

LoginComponent.propTypes = {
    labels: t.object,
};

LoginComponent.defaultProps = {
    defaults: {},
    completeSection: () => {},
};

export default LoginComponent;
